import React from "react";
import AddressForm from "./AddressForm";
import ShadowDomWrapper from "./components/ShadowDomWrapper";

const MypageRegistAddressContent = () => {
  return (
    <div className="max-w-3xl text-base mx-auto p-6">
      <div className="container mx-auto px-4 py-8">
        <AddressForm />
      </div>
      <div className="mt-6">
        <a
          href="/mypage"
          className="text-center block w-full max-w-sm mx-auto mt-8 px-4 py-2 border border-primary rounded-md text-sm font-medium text-primary bg-white hover:bg-primary hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
        >
          マイページへ
        </a>
      </div>
    </div>
  );
};

export default ShadowDomWrapper(MypageRegistAddressContent);
