import React, { useState, useEffect } from "react";
import axios from "axios";
import { useUser } from "./UserContext";
import config from "./config";

const NestaIdForm = () => {
  const { userData, updateUserData } = useUser();
  const [nestaId, setNestaId] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    if (userData) {
      setNestaId(userData.nestaId || "");
    }
  }, [userData]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!/^\d+$/.test(nestaId)) {
      setError("NESTA IDは半角数字のみ入力してください。");
      return;
    }
    try {
      const response = await axios.patch(
        `${config.apiUrl}/api/v1/user/${window.learnworldData.userId}`,
        { nestaId: nestaId }
      );
      setMessage("NestaIDが正常に更新されました");
      setTimeout(() => {
        setMessage("");
      }, 5000);
      updateUserData({ nestaId: nestaId });
      setIsEditing(false);
    } catch (error) {
      setError("NestaIDの更新に失敗しました");
    }
  };

  const handleChange = (e) => {
    const value = e.target.value;
    setNestaId(value);
    if (value && !/^\d+$/.test(value)) {
      setError("NESTA IDは半角数字のみ入力してください。");
    } else {
      setError("");
    }
  };

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleCancel = () => {
    setIsEditing(false);
    setNestaId("");
    setError("");
  };

  return (
    <div className="FS-NestaIdForm">
      <h2 className="FS-Mypage-Heading">NESTA IDのご登録</h2>
      <div>
        <div className="FS-Mypage-Description">
          <p>
            NESTA IDをご登録いただくことで、
            <a
              href="https://www.nesta-gfj.com/store/auth/login"
              target="_blank"
            >
              NESTA会員マイページ
            </a>
            に資格の単位を反映することが可能となります。
            <br />
            ご入力頂いたNESTA IDは、NESTA
            JAPANによる会員認証のために利用させていただきます。
          </p>
        </div>
        <div class="FS-NestaIdForm-Rows">
          {isEditing ? (
            <div>
              {!!userData && userData.nestaIdVerified == false && (
                <div>
                  <form onSubmit={handleSubmit}>
                    <label
                      htmlFor="nestaId"
                      style={{
                        marginBottom: "10px",
                        display: "block",
                        fontSize: "18px",
                        fontWeight: "bold",
                      }}
                    >
                      NESTA ID を入力してください
                    </label>
                    <p style={{ marginBottom: "10px" }}>
                      NESTA IDは
                      <a
                        href="https://www.nesta-gfj.com/store/auth/login"
                        target="_blank"
                      >
                        NESTA会員マイページ
                      </a>
                      にログイン後にご確認頂けます。
                    </p>
                    <div style={{ display: "flex", gap: "10px" }}>
                      <input
                        type="text"
                        name="nestaId"
                        value={nestaId}
                        onChange={handleChange}
                        placeholder="NESTA IDを入力してください"
                        required
                        className="landing-form-input learnworlds-input no-margin-bottom learnworlds-input-small learnworlds-input-solid-light"
                        style={{ width: "20em" }}
                      />
                      <button
                        type="submit"
                        disabled={!!error}
                        className="learnworlds-button no-margin-top learnworlds-element auto-button learnworlds-button-small learnworlds-button-solid-dark"
                      >
                        変更する
                      </button>
                      <button
                        className="learnworlds-button no-margin-top learnworlds-element auto-button learnworlds-button-small"
                        onClick={handleCancel}
                      >
                        キャンセル
                      </button>
                    </div>
                  </form>
                  {error && <p style={{ color: "red" }}>{error}</p>}
                </div>
              )}
              {message && <p>{message}</p>}
            </div>
          ) : (
            <>
              <div className="FS-NestaIdForm-Row">
                <div className="FS-NestaIdForm-Label">NESTA ID</div>
                <div className="FS-NestaIdForm-Value">
                  {!!userData && !!userData.nestaId
                    ? userData.nestaId
                    : "未設定"}
                </div>
              </div>
              <div className="FS-NestaIdForm-Row">
                <div className="FS-NestaIdForm-Label">認証状況</div>
                <div className="FS-NestaIdForm-Value">
                  <div>
                    {!!userData && userData.nestaIdVerified ? (
                      <p>認証済み</p>
                    ) : !!userData && userData.nestaIdVerifiedFailed ? (
                      <p>認証失敗</p>
                    ) : (
                      <p>未確認</p>
                    )}
                  </div>
                </div>
              </div>
              {!!userData && userData.nestaIdVerifiedFailed && (
                <div style={{ marginTop: "10px", color: "red" }}>
                  <p>
                    NESTA JAPANによる会員登録の確認ができませんでした。
                    <br />
                    会員登録されたメールアドレスにご連絡をさせて頂きますので、メールをご確認ください。
                  </p>
                </div>
              )}
              {!!userData && userData.nestaIdVerified == false && (
                <div style={{ marginTop: "10px" }}>
                  <button
                    onClick={handleEdit}
                    className="learnworlds-button no-margin-top learnworlds-element auto-button learnworlds-button-small learnworlds-button-solid-dark"
                  >
                    NESTA ID を設定する
                  </button>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default NestaIdForm;
