import React, { createContext, useContext } from 'react';
import { CourseData } from './types';

interface UserCourseContextType {
  courses: CourseData[];
  setCourses: React.Dispatch<React.SetStateAction<CourseData[]>>;
}

const UserCourseContext = createContext<UserCourseContextType | undefined>(undefined);

export const UserCourseProvider: React.FC<{ value: UserCourseContextType; children: React.ReactNode }> = ({ value, children }) => {
  return <UserCourseContext.Provider value={value}>{children}</UserCourseContext.Provider>;
};

export const useUserCourse = () => {
  const context = useContext(UserCourseContext);
  if (context === undefined) {
    throw new Error('useUserCourse must be used within a UserCourseProvider');
  }
  return context;
};