import React, { useRef, useEffect } from "react";
import ReactDOM from "react-dom";
import AddressForm from "./AddressForm";
import ShadowDomWrapper from "./components/ShadowDomWrapper";

function CourseFinishPage() {
  const hostRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (hostRef.current) {
      const shadowRoot = hostRef.current.attachShadow({ mode: "open" });
      const shadowContainer = document.createElement("div");
      shadowRoot.appendChild(shadowContainer);

      // 外部CSSを読み込む
      const linkElem = document.createElement("link");
      linkElem.setAttribute("rel", "stylesheet");
      linkElem.setAttribute("type", "text/css");
      linkElem.setAttribute(
        "href",
        "https://app-assets.fitschool.jp/assets/application.css"
      );
      shadowRoot.prepend(linkElem);

      // 追加のスタイルを設定する場合
      const style = document.createElement("style");
      style.textContent = `
        // ここに追加のスタイルを記述
      `;
      shadowRoot.appendChild(style);

      ReactDOM.render(<CourseFinishPageContentContainer />, shadowContainer);
    }
  }, []);

  return <div ref={hostRef}></div>;
}

function CourseFinishPageContentContainer() {
  const [isReady, setIsReady] = React.useState(false);

  React.useEffect(() => {
    const timer = setTimeout(() => {
      setIsReady(true);
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  return isReady ? <CourseFinishPageContent /> : null;
}

function CourseFinishPageContent() {
  // 既存のコンポーネントの内容
  return (
    <div className="max-w-3xl text-base mx-auto p-6">
      <div className="text-center mb-8">
        <div className="inline-block bg-lime-400 rounded-full p-4 mb-4">
          <svg
            className="w-8 h-8 text-white"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M5 13l4 4L19 7"
            />
          </svg>
        </div>
        <div className="border-2 border-green-500 bg-green-50 rounded-lg p-6 mb-6">
          <h3 className="text-xl font-bold text-green-700 mb-4">
            コース修了おめでとうございます！
          </h3>
          <p className="text-green-800 mb-3">
            マイページにてNESTA資格の単位の計上が行われております。
          </p>
          <p className="text-green-800 mb-3">
            NESTA IDの登録とNESTAによる認証が行われていないと
            <br />
            資格の取得が行われません。
          </p>
          <p className="text-green-800 font-semibold">
            NESTA IDの登録を行っていない場合は、
            <br />
            マイページからご対応をお願いいたします。
          </p>
        </div>
      </div>
      <div className="mt-6">
        <AddressForm />
      </div>
      <div className="mt-20">
        <a
          href="/mypage"
          className="text-center block w-full max-w-sm mx-auto mt-8 px-4 py-2 border border-primary rounded-md text-sm font-medium text-primary bg-white hover:bg-primary hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
        >
          マイページへ
        </a>
      </div>
    </div>
  );
}

export default ShadowDomWrapper(CourseFinishPage);
