import React, { useState, useEffect } from "react";
import axios from "axios";
import NestaIdForm from "./nestaIdForm";
import { UserProvider } from "./UserContext";
import { UserCourseProvider } from "./UserCourseContext";
import config from "./config";
import { UserData, CourseData } from "./types";

const MyPage = () => {
  const [userData, setUserData] = useState<UserData | null>(null);
  const [courses, setCourses] = useState<CourseData[]>([]);

  useEffect(() => {
    if (!window.learnworldData.userId) {
      window.location.href = "https://fitschool.jp/?msg=not-logged-in";
      return;
    }

    fetchUserData();
    fetchCourses();
  }, []);

  const fetchUserData = async () => {
    try {
      const response = await axios.get(
        `${config.apiUrl}/api/v1/user/${window.learnworldData.userId}`
      );
      setUserData(response.data);
    } catch (error) {
      console.error("ユーザーデータの取得に失敗しました:", error);
    }
  };

  const fetchCourses = async () => {
    try {
      const response = await axios.get(
        `${config.apiUrl}/api/v1/user/${window.learnworldData.userId}/courses`
      );
      setCourses(response.data);
    } catch (error) {
      console.error("コースデータの取得に失敗しました:", error);
    }
  };

  const updateUserData = (newData) => {
    setUserData((prevData) => ({ ...prevData, ...newData }));
  };

  return (
    <UserProvider value={{ userData, updateUserData }}>
      <UserCourseProvider value={{ courses, setCourses }}>
        <div className="FS-Mypage" style={{ fontSize: "initial" }}>
          <h1 class="FS-Mypage-Title Font-Arimo Font-Italic">My Page</h1>
          <NestaIdForm />
          <div>
            <h2 className="FS-Mypage-Heading">受講コース一覧</h2>
            {!!userData && (
              <div className="FS-NestaIdForm-Rows mb-20">
                <div className="FS-NestaIdForm-Row">
                  <div className="FS-NestaIdForm-Label">修了済み単位数</div>
                  <div className="FS-NestaIdForm-Value">
                    {userData.totalCredit}
                  </div>
                </div>
              </div>
            )}
            {!!courses && courses.length > 0 ? (
              <table className="FS-Course-Table">
                <thead>
                  <tr>
                    <th>コース名</th>
                    <th>購入日</th>
                    <th>進捗</th>
                    <th>単位数</th>
                  </tr>
                </thead>
                <tbody>
                  {courses.map((course) => (
                    <tr key={course.id}>
                      <td>
                        <a href={`https://fitschool.jp/course/${course.id}`}>
                          {course.title}
                        </a>
                      </td>
                      <td>
                        {new Date(course.enrolled_at)
                          .toLocaleDateString("ja-JP", {
                            year: "numeric",
                            month: "2-digit",
                            day: "2-digit",
                          })
                          .replace(/\//g, "年")
                          .replace(/(\d+)$/, "$1日")}
                      </td>
                      <td className="WhiteSpace-nowrap">
                        {course.completed ? "修了" : "未修了"}
                      </td>
                      <td className="WhiteSpace-nowrap">{course.credit}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <div style={{ marginBottom: "40px" }}>
                <p style={{ marginBottom: "10px" }}>
                  受講中のコースはありません。コースを探して受講しましょう。
                </p>
                <button
                  className="learnworlds-button no-margin-top learnworlds-element auto-button learnworlds-button-small learnworlds-button-solid-dark"
                  onClick={() =>
                    (window.location.href = "https://fitschool.jp/courses")
                  }
                >
                  コースを探す
                </button>
              </div>
            )}
          </div>

          <div style={{ marginTop: "40px" }}>
            <h2 className="FS-Mypage-Heading">NESTAマガジンの購読</h2>
            <div>
              NESTAより定期的に発行されるマガジンを購読することができます。
            </div>
            <div style={{ marginTop: "10px" }}>
              <a
                className="learnworlds-button no-margin-top learnworlds-element auto-button learnworlds-button-small learnworlds-button-solid-dark"
                href="https://fitschool.jp/regist-address"
              >
                登録はこちら
              </a>
            </div>
          </div>
        </div>
      </UserCourseProvider>
    </UserProvider>
  );
};
export default MyPage;
