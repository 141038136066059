export default function PaidFreeCourseHidden() {
  const currentPath = window.location.pathname;
  if (
    currentPath == "/" ||
    currentPath.includes("/course") ||
    currentPath.includes("/home")
  ) {
    const intervalId = setInterval(() => {
      if (processCards(currentPath)) {
        clearInterval(intervalId);
      }
    }, 1000);
  }
}

function processCards(currentPath: string) {
  const containers = document.querySelectorAll(
    ".js-render-wrapper.lw-cols.multiple-rows"
  );
  if (containers.length === 0) return false;

  containers.forEach(function (courseCardsContainer) {
    const courseCards =
      courseCardsContainer.querySelectorAll(".lw-course-card");

    let totalWidth = 0;
    let visibleCards = 0;

    courseCards.forEach(function (card) {
      const link = card.querySelector("a.lw-course-card--stretched-link");
      if (link) {
        const href = link.getAttribute("href");
        if (href && (href.includes("-01") || href.includes("-02"))) {
          card.style.display = "none";
        } else {
          totalWidth += card.offsetWidth;
          visibleCards++;
        }
      }
    });

    // コースページは除外
    if (currentPath === "/" || currentPath.includes("/home")) {
      // コンテナの幅を設定
      if (courseCardsContainer && visibleCards > 0) {
        const containerPadding = 20; // パディングの値を適宜調整してください
        courseCardsContainer.style.width = `${
          totalWidth + (visibleCards - 1) * containerPadding
        }px`;
      }
    }
  });

  // コースページはclearIntervalの対象から除外
  if (currentPath.includes("/courses")) {
    return false;
  }

  return true;
}
