import config from "./config";
import { UserApiResponse } from "./types";
import { showLoading, hideLoading } from "./utils/loading";

export default class CourseRedirectHandler {
  private isLogined: boolean;
  private redirectTo01: boolean;
  private redirectTo02: boolean;
  private currentPath: string;
  private currentCourseId: string | null;
  private enrolledCourses: string[] = [];

  constructor() {
    // isLoginedは、LWにログインしているかどうかを示すフラグです。
    // 有料会員・無料会員の区別とは関係ありません。
    this.isLogined = window.learnworldData.userId !== "";
    this.redirectTo01 = window.learnworldData.redirectTo01 || false;
    this.redirectTo02 = window.learnworldData.redirectTo02 || false;
    this.currentPath = window.location.pathname;
    this.currentCourseId = this.currentPath.split("/").pop();
  }

  /**
   * CourseRedirectHandlerの初期化を行う
   * コースページでない場合やnoredirectパラメータがある場合は処理を中断する
   * それ以外の場合は、登録コースを取得してリダイレクト処理を行う
   */
  public async init(): Promise<void> {
    if (!this.currentPath.startsWith("/course/")) {
      return;
    }

    if (window.location.search.includes("noredirect")) {
      return;
    }

    await this.fetchEnrolledCourses();
    this.handleRedirection();
  }

  /**
   * ユーザーの登録コースリストを取得し、更新する
   * window.learnworldDataからコース情報を取得し、
   * this.enrolledCoursesを更新する
   * @returns Promise<void>
   */
  private fetchEnrolledCourses(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const userId = window.learnworldData?.userId;

      if (!userId) {
        resolve(); // ユーザーIDがない場合は早期リターン
        return;
      }

      try {
        this.enrolledCourses = window.learnworldData.user.courses
          .split(",")
          .map((course) => course.trim());
        resolve();
      } catch (error) {
        console.error("コースデータの取得中にエラーが発生しました:", error);
        resolve();
      }
    });
  }

  /**
   * 指定されたパスが存在するかチェックし、存在する場合はリダイレクトする
   * @param newPath リダイレクト先のパス
   */
  private async checkAndRedirect(newPath: string): Promise<void> {
    try {
      const response = await fetch(newPath, { method: "HEAD" });
      if (response.status === 200) {
        window.location.href = newPath;
      } else {
        console.error(`リダイレクト先（${newPath}）が存在しません。`);
      }
    } catch (error) {
      console.error("リダイレクトチェック中にエラーが発生しました:", error);
    }
  }

  /**
   * 現在のコースIDに指定されたサフィックスを追加してリダイレクトを行う
   * サフィックスがnullの場合は、現在のコースIDにリダイレクトする
   * @param suffix リダイレクト先のサフィックス（"-01"、"-02"、またはnull）
   */
  private handleRedirect(suffix: string | null): void {
    if (suffix === null) {
      this.checkAndRedirect(this.currentCourseId);
    } else {
      this.checkAndRedirect(`${this.currentCourseId}${suffix}`);
    }
  }

  /**
   * リダイレクション処理のメインロジック
   * 購入後の場合と通常の場合で異なる処理を行う
   */
  private handleRedirection(): void {
    const urlParams = new URLSearchParams(window.location.search);
    const isAfterPurchase = urlParams.get("after_purchase") === "true";
    const currentFullCourseId = this.currentPath.split("/").pop() || "";
    const currentBaseCourseId = currentFullCourseId.split("-")[0];

    if (isAfterPurchase) {
      if (currentFullCourseId.includes("-")) {
        this.handleAfterPurchaseWithHyphen(currentBaseCourseId);
      } else {
        // ハイフンなしの場合は何もしない
        return;
      }
    } else {
      // 既存のリダイレクトロジック
      this.handleExistingRedirectLogic(
        currentFullCourseId,
        currentBaseCourseId
      );
    }
  }

  /**
   * 購入後のリダイレクト処理（ハイフンありの場合）
   * 3秒ごとにリロードを繰り返し、enrolledCoursesを更新して
   * baseCourseIdが含まれているかチェックする。
   * 含まれている場合は該当コースへリダイレクトし、
   * 含まれていない場合は引き続きリロードを行う。
   * @param baseCourseId ベースとなるコースID
   */
  private handleAfterPurchaseWithHyphen(baseCourseId: string): void {
    showLoading();
    const checkEnrollmentAndRedirect = () => {
      // まず、enrolledCoursesを更新
      this.fetchEnrolledCourses().then(() => {
        if (this.enrolledCourses.includes(baseCourseId)) {
          window.location.href = `/course/${baseCourseId}`;
          hideLoading();
        } else {
          // 3秒後にリロード
          setTimeout(() => {
            window.location.reload();
          }, 3000);
        }
      });
    };
    checkEnrollmentAndRedirect();
  }

  /**
   * 既存のリダイレクトロジックを処理する
   * ログイン状態、コースの購入状態、リダイレクト設定に基づいて
   * 適切なリダイレクト先を決定し、リダイレクトを行う
   * @param currentFullCourseId 現在のフルコースID
   * @param currentBaseCourseId 現在のベースコースID
   */
  private handleExistingRedirectLogic(
    currentFullCourseId: string,
    currentBaseCourseId: string
  ): void {
    if (this.isLogined) {
      // 現在のコースが無印（ハイフンなし）で、かつ購入済みの場合はリダイレクトしない
      if (
        currentFullCourseId === currentBaseCourseId &&
        this.enrolledCourses.includes(currentFullCourseId)
      ) {
        return;
      }

      // 現在のコースが無印（ハイフンなし）で、かつ購入済みでない場合はリダイレクトする
      if (
        currentFullCourseId === currentBaseCourseId &&
        !this.enrolledCourses.includes(currentFullCourseId)
      ) {
        if (this.redirectTo01) {
          this.handleRedirect("-01");
          return;
        } else if (this.redirectTo02) {
          this.handleRedirect("-02");
          return;
        }
      }

      /* 以下は、コースがハイフンありの場合 */

      // 購入済みの場合、無印のコースにリダイレクト
      if (
        currentBaseCourseId &&
        this.enrolledCourses.includes(currentBaseCourseId)
      ) {
        this.checkAndRedirect(`/course/${currentBaseCourseId}`);
        return;
      }

      if (this.currentPath.endsWith("-01")) {
        if (!this.redirectTo01 && !this.redirectTo02) {
          this.checkAndRedirect(`/course/${currentBaseCourseId}`);
        } else if (this.redirectTo02) {
          this.handleRedirect("-02");
        }
      } else if (this.currentPath.endsWith("-02")) {
        if (!this.redirectTo01 && !this.redirectTo02) {
          this.checkAndRedirect(`/course/${currentBaseCourseId}`);
        } else if (this.redirectTo01) {
          this.handleRedirect("-01");
        }
      }
    } else {
      if (
        this.currentPath.endsWith("-01") ||
        this.currentPath.endsWith("-02")
      ) {
        this.checkAndRedirect(`/course/${currentBaseCourseId}`);
        return;
      }
    }
  }
}
