const config = {
  development: {
    apiUrl: "https://dev-app.fitschool.jp",
  },
  production: {
    apiUrl: "https://app.fitschool.jp",
  },
};

export default config[process.env.NODE_ENV || "development"];
