import React, { useState, useEffect } from "react";
import axios from "axios";
import config from "./config";
import { prefectures } from "./components/prefecture";

interface FormErrors {
  postalCode?: string;
  prefecture?: string;
  city?: string;
  street?: string;
}

function AddressFormContent() {
  const [formData, setFormData] = useState({
    postalCode: "",
    prefecture: "",
    city: "",
    street: "",
    building: "",
    wantsMagazine: false,
  });
  const [errors, setErrors] = useState<FormErrors>({});
  const [submitError, setSubmitError] = useState<string | null>(null);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axios.get(
          `${config.apiUrl}/api/v1/user/${window.learnworldData.userId}`
        );
        const userData = response.data;
        setFormData({
          postalCode: userData.postalCode || "",
          prefecture: userData.prefecture || "",
          city: userData.city || "",
          street: userData.street || "",
          building: userData.building || "",
          wantsMagazine: userData.wantsMagazine || false,
        });
      } catch (error) {
        console.error("ユーザーデータの取得に失敗しました:", error);
      }
    };

    fetchUserData();
  }, []);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (name === "postalCode") {
      // 郵便番号フィールドの場合、半角数字以外を除去
      const sanitizedValue = value.replace(/[^\d]/g, "");
      setFormData((prev) => ({
        ...prev,
        [name]: sanitizedValue,
      }));
      if (sanitizedValue.length === 7) {
        autoFillAddress(sanitizedValue);
      }
    } else {
      setFormData((prev) => ({
        ...prev,
        [name]: type === "checkbox" ? checked : value,
      }));
    }
  };

  const autoFillAddress = async (postalCode: string) => {
    try {
      const response = await axios.get(
        `https://jp-postal-code-api.ttskch.com/api/v1/${postalCode}.json`
      );
      const data = response.data;

      if (data.addresses && data.addresses.length > 0) {
        const address = data.addresses[0];
        setFormData((prev) => ({
          ...prev,
          prefecture: address.ja.prefecture,
          city: address.ja.address1,
          //   street: `${address.ja.address2}${address.ja.address3}`.trim(),
        }));
      }
    } catch (error) {
      console.error("郵便番号の取得に失敗しました", error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const newErrors: FormErrors = {};
    if (!formData.postalCode) {
      newErrors.postalCode = "郵便番号は必須です";
    } else if (!/^\d{7}$/.test(formData.postalCode)) {
      newErrors.postalCode = "郵便番号は7桁の半角数字で入力してください";
    }
    if (!formData.prefecture) newErrors.prefecture = "都道府県は必須です";
    if (!formData.city) newErrors.city = "市区町村は必須です";
    if (!formData.street) newErrors.street = "番地は必須です";

    if (Object.keys(newErrors).length === 0) {
      try {
        setSubmitError(null); // リクエスト開始時にエラーをクリア
        const response = await axios.put(
          `${config.apiUrl}/api/v1/user/${window.learnworldData.userId}/address`,
          {
            address: {
              postal_code: formData.postalCode,
              prefecture: formData.prefecture,
              city: formData.city,
              street: formData.street,
              building: formData.building,
              wants_magazine: formData.wantsMagazine,
            },
          }
        );
        console.log("住所が更新されました:", response.data);
        window.location.href = "/mypage";
      } catch (error) {
        console.error("住所の更新に失敗しました:", error);
        if (axios.isAxiosError(error) && error.response) {
          // サーバーからのエラーメッセージがある場合はそれを表示
          setSubmitError(
            error.response.data.errors?.join(", ") ||
              "住所の更新に失敗しました。もう一度お試しください。"
          );
        } else {
          setSubmitError(
            "予期せぬエラーが発生しました。もう一度お試しください。"
          );
        }
      }
    } else {
      setErrors(newErrors);
    }
  };

  return (
    <form
      onSubmit={handleSubmit}
      className="max-w-3xl text-base mx-auto p-12 bg-white rounded-lg shadow-md"
    >
      <h2 className="text-xl font-bold mb-6 text-center">住所のご登録</h2>
      <p className="text-base mb-6 text-gray-600 text-center">
        NESTAよりマガジンの配送を希望される方はご住所の入力をお願いします。
      </p>

      {submitError && (
        <div className="mb-4 p-3 bg-red-100 border border-red text-red rounded">
          {submitError}
        </div>
      )}

      <div className="space-y-6">
        <div>
          <label
            htmlFor="postalCode"
            className="block text-sm font-bold text-gray-700 mb-1"
          >
            郵便番号<span className="text-red">*</span>
          </label>
          <input
            type="text"
            id="postalCode"
            name="postalCode"
            value={formData.postalCode}
            onChange={handleChange}
            className={`mt-1 block w-full px-3 py-2 rounded-md border border-gray-300 focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 ${
              errors.postalCode ? "border-red-500" : ""
            }`}
            placeholder="1500001"
            maxLength={7}
          />
          {errors.postalCode && (
            <p className="mt-1 text-xs text-red">{errors.postalCode}</p>
          )}
        </div>

        <div>
          <label
            htmlFor="prefecture"
            className="block text-sm font-bold text-gray-700 mb-1"
          >
            都道府県<span className="text-red">*</span>
          </label>
          <select
            id="prefecture"
            name="prefecture"
            value={formData.prefecture}
            onChange={handleChange}
            className={`mt-1 block w-full px-3 py-2 rounded-md border border-gray-300 focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 ${
              errors.prefecture ? "border-red-500" : ""
            }`}
          >
            <option value="">選択してください</option>
            {prefectures.map((pref) => (
              <option key={pref} value={pref}>
                {pref}
              </option>
            ))}
          </select>
          {errors.prefecture && (
            <p className="mt-1 text-xs text-red">{errors.prefecture}</p>
          )}
        </div>

        <div>
          <label
            htmlFor="city"
            className="block text-sm font-bold text-gray-700 mb-1"
          >
            市区町村<span className="text-red">*</span>
          </label>
          <input
            type="text"
            id="city"
            name="city"
            value={formData.city}
            onChange={handleChange}
            className={`mt-1 block w-full px-3 py-2 rounded-md border border-gray-300 focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 ${
              errors.city ? "border-red-500" : ""
            }`}
          />
          {errors.city && (
            <p className="mt-1 text-xs text-red">{errors.city}</p>
          )}
        </div>

        <div>
          <label
            htmlFor="street"
            className="block text-sm font-bold text-gray-700 mb-1"
          >
            番地<span className="text-red">*</span>
          </label>
          <input
            type="text"
            id="street"
            name="street"
            value={formData.street}
            onChange={handleChange}
            className={`mt-1 block w-full px-3 py-2 rounded-md border border-gray-300 focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 ${
              errors.street ? "border-red-500" : ""
            }`}
          />
          {errors.street && (
            <p className="mt-1 text-xs text-red">{errors.street}</p>
          )}
        </div>

        <div>
          <label
            htmlFor="building"
            className="block text-sm font-bold text-gray-700 mb-1"
          >
            建物名・部屋番号
          </label>
          <input
            type="text"
            id="building"
            name="building"
            value={formData.building}
            onChange={handleChange}
            className="mt-1 block w-full px-3 py-2 rounded-md border border-gray-300 focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
          />
        </div>

        <div className="flex items-center mt-6">
          <input
            type="checkbox"
            id="wantsMagazine"
            name="wantsMagazine"
            checked={formData.wantsMagazine}
            onChange={handleChange}
            className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border border-gray-300 rounded"
          />
          <label
            htmlFor="wantsMagazine"
            className="ml-2 block text-sm text-gray-900"
          >
            マガジンの配送を希望される方はチェックを入れてください
          </label>
        </div>
      </div>

      <div className="flex justify-center">
        <button
          type="submit"
          className="w-full max-w-sm mx-auto mt-8 px-4 py-2 border border-transparent rounded-md text-sm font-medium text-white bg-primary hover:bg-primary-dark hover:opacity-80 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
        >
          送信
        </button>
      </div>
    </form>
  );
}

export default AddressFormContent;
