import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";

export default function ShadowDomWrapper(
  WrappedComponent: React.ComponentType
) {
  return () => {
    const hostRef = React.useRef<HTMLDivElement>(null);

    React.useEffect(() => {
      if (hostRef.current) {
        const shadowRoot = hostRef.current.attachShadow({ mode: "open" });
        const shadowContainer = document.createElement("div");
        shadowRoot.appendChild(shadowContainer);

        const linkElem = document.createElement("link");
        linkElem.setAttribute("rel", "stylesheet");
        linkElem.setAttribute("type", "text/css");
        linkElem.setAttribute(
          "href",
          "https://app-assets.fitschool.jp/assets/application.css"
        );
        shadowRoot.prepend(linkElem);

        const DelayedRender: React.FC = () => {
          const [shouldRender, setShouldRender] = useState(false);

          useEffect(() => {
            const timer = setTimeout(() => {
              setShouldRender(true);
            }, 300);

            return () => clearTimeout(timer);
          }, []);

          return shouldRender ? <WrappedComponent /> : null;
        };

        ReactDOM.render(<DelayedRender />, shadowContainer);
      }
    }, []);

    return <div ref={hostRef}></div>;
  };
}
