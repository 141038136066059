import React from "react";
import { createRoot } from "react-dom/client";
import MyPage from "./src/mypage";
import CourseRedirectHandler from "./src/CourseRedirectHandler";
import CourseDataFetcher from "./src/CourseDataFetcher";
import CourseFinishPage from "./src/CourseFinishPage";
import MypageRegistAddress from "./src/MypageRegistAddress";
import PaidFreeCourseHidden from "./src/PaidFreeCourseHidden";

document.addEventListener("DOMContentLoaded", () => {
  new CourseRedirectHandler().init();
  PaidFreeCourseHidden();

  const reactAnyElement = document.getElementById("react-any");
  if (reactAnyElement) {
    const root = createRoot(reactAnyElement);
    root.render(<CourseDataFetcher />);
  }

  const reactMypageElement = document.getElementById("react-mypage");
  if (reactMypageElement) {
    const root = createRoot(reactMypageElement);
    root.render(<MyPage />);
  }

  const reactMypageRegistAddress = document.getElementById(
    "react-mypage-regist-address"
  );
  if (reactMypageRegistAddress) {
    const root = createRoot(reactMypageRegistAddress);
    root.render(<MypageRegistAddress />);
  }

  const reactCourseFinishPage = document.getElementById(
    "react-course-finish-page"
  );
  if (reactCourseFinishPage) {
    const root = createRoot(reactCourseFinishPage);
    root.render(<CourseFinishPage />);
  }
});
