let loadingCount = 0;
let loadingElement: HTMLDivElement | null = null;

function createLoadingElement(): HTMLDivElement {
  const loader = document.createElement("div");
  loader.className = "loader";
  loader.innerHTML = `
    <div class="spinner"></div>
  `;
  return loader;
}

export function showLoading(): void {
  loadingCount++;
  if (!loadingElement) {
    loadingElement = createLoadingElement();
    document.body.insertAdjacentElement("afterbegin", loadingElement);
  }
  loadingElement.style.display = "flex";
}

export function hideLoading(): void {
  loadingCount--;
  if (loadingCount <= 0) {
    loadingCount = 0;
    if (loadingElement) {
      loadingElement.style.display = "none";
    }
  }
}
